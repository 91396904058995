import { authHeader, handleResponse } from './auth-header';

export const allergyService = {
    getAllergy,
    storeAllergy,
    deleteAllergy,
    getAllergyByID,
    updateAllergy,
    updateStatusAllergy
};

function getAllergy(search, existingPage, perPage) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `allergy?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeAllergy(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)

    };

    return fetch(import.meta.env.VITE_API_URL + `allergy`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteAllergy(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `allergy/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getAllergyByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `allergy/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateAllergy(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)

    };
    return fetch(import.meta.env.VITE_API_URL + `allergy/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusAllergy(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `allergy/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}