// user.module.js

import { userService } from '../../helpers/backend/user.service';
import { packageService } from '../../helpers/backend/package.service';
import router from '../../router/index';
import { error } from 'jquery';

const user = JSON.parse(localStorage.getItem('user'));
const listing = [];
const state = {
    user:
        user ? { status: { loggeduser: true }, user }
            : { status: {}, user: null },
    listing: listing
}
    ;

const actions = {
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                }
            );
    },
    logout({ commit }) {
        userService.logout().then(user => {
            commit('logout');
            router.push('/login');

        });

    },
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                }
            );
    },
    changePassword({ dispatch, commit }, { oldpassword, newpassword }) {
        return userService.changePassword(oldpassword, newpassword)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    getsuperadmin({ dispatch, commit }, user) {
        return userService.getsuperadmin()
            .then(
                user => {
                    commit('loginSuccess', user);
                    return user;
                },
                error => {
                    return error;
                }
            );
    },
    updateSuperAdmin({ dispatch, commit }, { id, name }) {
        return userService.updateSuperAdmin(id, name).then(
            result => {
                return result;
            },
            error => {
                return error;
            }
        )
    }

};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    packageListing(state, user) {
        state.listing = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}