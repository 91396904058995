import { authHeader, handleResponse } from './auth-header';

export const commonService = {
    getCountry,
    getState,
    getCity
};

function getCountry(search, existingPage, perPage) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `country?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function getState(search, existingPage, perPage,countryID) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `state?search=` + search + `&page=` + existingPage + `&perPage=` + perPage+`&countryID=`+countryID, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function getCity(search, existingPage, perPage,stateID) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `city?search=` + search + `&page=` + existingPage + `&perPage=` + perPage+`&stateID=`+stateID, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}