import { authHeader, handleResponse } from '../auth-header';

export const doctorAppointmentService = {
    getDoctorAppointment,
    storeDoctorAppointment,
    deletePatient,
    getPatientByID,
    updatePatient,
    updateStatusPatient
};

function getDoctorAppointment(search, existingPage, perPage, doctorID) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `doctor_appointment?search=` + search + `&page=` + existingPage + `&perPage=` + perPage + `&doctorID=` + doctorID, requestOptions)
        .then(handleResponse)
        .then(result => {
            console.log("Service", result)
            return result;
        });
}
function storeDoctorAppointment(patient) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(patient)

    };

    return fetch(import.meta.env.VITE_API_URL + `doctor_appointment`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deletePatient(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `patient/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getPatientByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `patient/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updatePatient(id, Patient) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(Patient)

    };
    return fetch(import.meta.env.VITE_API_URL + `patient/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusPatient(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `patient/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}