import { authHeader, handleResponse } from './auth-header';

export const medicineTypeService = {
    getMedicineType,
    storeMedicineType,
    deleteMedicineType,
    getMedicineTypeByID,
    updateMedicineType,
    updateStatusMedicineType
};

function getMedicineType(search, existingPage, perPage) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `medicinetype?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeMedicineType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ name })

    };

    return fetch(import.meta.env.VITE_API_URL + `medicinetype`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteMedicineType(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `medicinetype/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getMedicineTypeByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `medicinetype/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateMedicineType(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)

    };
    return fetch(import.meta.env.VITE_API_URL + `medicinetype/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusMedicineType(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `medicinetype/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
