import { authHeader, handleResponse } from './auth-header';

export const doctorService = {
    getDoctor,
    updateStatusDoctor,
    getDoctorByID,
    deleteDoctor,
    updateDoctor
};

function getDoctor(search, existingPage, perPage) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `doctor?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteDoctor(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `doctor/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getDoctorByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `doctor/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusDoctor(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `doctor/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateDoctor(id, doctor) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body:JSON.stringify(doctor)

    };
    return fetch(import.meta.env.VITE_API_URL + `doctor/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}