import { authHeader, handleResponse } from './auth-header';

export const labtestService = {
    getLabTest,
    storeLabTest,
    deleteLabTest,
    getLabTestByID,
    updateLabTest,
    updateStatusLabTest
};

function getLabTest(search, existingPage, perPage) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `labtest?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeLabTest(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)

    };

    return fetch(import.meta.env.VITE_API_URL + `labtest`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteLabTest(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `labtest/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getLabTestByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `labtest/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateLabTest(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)

    };
    return fetch(import.meta.env.VITE_API_URL + `labtest/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusLabTest(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `labtest/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}