import { authHeader, handleResponse } from './auth-header';

export const packageService = {
    getPackage,
    storePackage,
    deletePackage,
    getPackageByID,
    getAll,
    updatePackage,
    updateStatusPackage
};

function getPackage(search, existingPage, perPage) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };
    return fetch(import.meta.env.VITE_API_URL + `package?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storePackage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)

    };
    return fetch(import.meta.env.VITE_API_URL + `package`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deletePackage(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };
    return fetch(import.meta.env.VITE_API_URL + `package/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getPackageByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `package/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updatePackage(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)

    };
    return fetch(import.meta.env.VITE_API_URL + `package/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusPackage(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `package/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/ users`, requestOptions).then(handleResponse);
}
