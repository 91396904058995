import { authHeader, handleResponse } from './auth-header';

export const medicineService = {
    getMedicine,
    storeMedicine,
    deleteMedicine,
    getMedicineByID,
    updateMedicine,
    updateStatusMedicine
};

function getMedicine(search, existingPage, perPage, medicineTypeID) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `medicine?search=` + search + `&page=` + existingPage + `&perPage=` + perPage + `&medicineTypeID=` + medicineTypeID, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeMedicine(medicine) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(medicine)

    };

    return fetch(import.meta.env.VITE_API_URL + `medicine`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteMedicine(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `medicine/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getMedicineByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `medicine/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateMedicine(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)

    };
    return fetch(import.meta.env.VITE_API_URL + `medicine/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusMedicine(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };
    return fetch(import.meta.env.VITE_API_URL + `medicine/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
