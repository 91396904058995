import { authHeader, handleResponse } from './auth-header';

export const diseasesService = {
    getDiseases,
    storeDiseases,
    deleteDiseases,
    getDiseasesByID,
    updateDisease,
    updateStatusDisease
};

function getDiseases(search, existingPage, perPage) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `diseases?search=` + search + `&page=` + existingPage + `&perPage=` + perPage, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function storeDiseases(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)

    };

    return fetch(import.meta.env.VITE_API_URL + `diseases`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function deleteDiseases(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ id })

    };

    return fetch(import.meta.env.VITE_API_URL + `diseases/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getDiseasesByID(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(import.meta.env.VITE_API_URL + `diseases/` + id + `/edit`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateDisease(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)

    };

    return fetch(import.meta.env.VITE_API_URL + `diseases/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function updateStatusDisease(is_active, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ is_active })

    };

    return fetch(import.meta.env.VITE_API_URL + `diseases/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}