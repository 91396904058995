// user.module.js

import { userService } from '../../helpers/backend/Doctor/user.service';
import router from '../../router/index';
import { error } from 'jquery';

const doctorUser = JSON.parse(localStorage.getItem('doctorUser'));
const listing = [];
const state = {
    doctorUser:
        doctorUser ? { status: { loggeduser: true }, doctorUser }
            : { status: {}, doctorUser: null },
    listing: listing
}
    ;

const actions = {
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);
        return userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/doctor/login');
                },
                error => {
                    commit('registerFailure', error);
                    return error;
                }
            );
    },
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        return userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/doctor/dashboard');
                },
                error => {
                    commit('loginFailure', error);
                    return error;
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },

    changePassword({ dispatch, commit }, { oldpassword, newpassword }) {
        return userService.changePassword(oldpassword, newpassword)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    getDoctor({ dispatch, commit }, user) {
        return userService.getDoctor()
            .then(
                user => {
                    commit('loginSuccess', user);
                    return user;
                },
                error => {
                    return error;
                }
            );
    },
    updateDoctorDetails({ dispatch, commit }, { id, doctor }) {
        return userService.updateDoctorDetails(id, doctor).then(
            result => {
                return result;
            },
            error => {
                return error;
            }
        )
    }

};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.doctorUser = user;
    },

    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.doctorUser = user;
    },
    loginFailure(state) {
        state.status = {};
        state.doctorUser = null;
    },
    logout(state) {
        state.status = {};
        state.doctorUser = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}