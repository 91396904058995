import store from '../state/store';
import { defineAsyncComponent, ref } from 'vue';

export default [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/account/login.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('authfack/logout');

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                );
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom });
            },
        },
    },
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index.vue')
    },
    {
        path: '/doctor',
        name: 'Doctor',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctorList/index.vue')
    },
    {
        path: '/patient',
        name: 'Patient',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/patient/index.vue')
    },
    {
        path: '/package',
        name: 'Package',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/package/index.vue')
    },
    {
        path: '/setting/allergy',
        name: 'Allergy',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/allergy/index.vue')
    },
    {
        path: '/setting/appointment',
        name: 'Appointment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/appointment/index.vue')
    },
    {
        path: '/setting/diseases',
        name: 'Diseases',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/diseases/index.vue')
    },
    {
        path: '/setting/medicineType',
        name: 'MedicineType',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/medicineType/index.vue')
    },
    {
        path: '/setting/labTest',
        name: 'LabTest',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/labtest/index.vue')
    },
    {
        path: '/setting/change-password',
        name: 'Change Password',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/profile/change-password.vue')
    },
    {
        path: '/setting/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/profile/index.vue')
    },
    {
        path: '/setting/medicine/:medicineTypeID',
        name: 'Medicine',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/medicine/index.vue'),
        props: true
    },
    {
        path: '/doctor/view/:doctorID',
        name: 'View Doctor',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctorList/doctor-view.vue'),
        props: true
    },
    {
        path: '/doctor/edit/:doctorID',
        name: 'Edit Doctor',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctorList/doctor-edit.vue'),
        props: true
    },
    {
        path: '/doctor/register/',
        name: 'Register',
        component: defineAsyncComponent(() => import('../views/pages/account/register.vue')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                //   If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/doctor/login/',
        name: 'DoctorLogin',
        component: defineAsyncComponent(() => import('../views/pages/account/doctor-login.vue')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/doctor/logout',
        name: 'DoctorLogout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('doctorauth/logout');

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/doctor/login')
                );
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'DoctorDashboard' } : { ...routeFrom });
            },
        },
    },
    {
        path: '/doctor/dashboard',
        name: 'DoctorDashboard',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/dashboard/index.vue')
    },
    {
        path: '/doctor/clinic',
        name: 'Doctor Clinic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/clinic/index.vue')
    },
    {
        path: '/doctor/patient',
        name: 'Doctor Patient',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/patient/index.vue')
    },
    {
        path: '/doctor/appointment',
        name: 'Doctor Appointment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/appointment/index.vue')
    },
    {
        path: '/doctor/appointment/add/:appointmentID',
        name: 'Add Appointment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/appointment/appointment-add.vue'),
        props: true
    },
    {
        path: '/doctor/medicine',
        name: 'Doctor Add Medicine',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/medicine/index.vue'),
        props: true
    },
    {
        path: '/doctor/change-password',
        name: 'Doctor Change Password',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/profile/change-password.vue')
    },
    {
        path: '/doctor/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/doctor/profile/index.vue')
    },

];
