import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
    let doctor = JSON.parse(localStorage.getItem('doctorUser'));
    let token = JSON.parse(localStorage.getItem('token')) || JSON.parse(sessionStorage.getItem('token'));
    if (user || doctor) {
        //return { 'Authorization': 'Bearer 7|RRiOqLMoE0FvlUskdq8ThLDZGzB0SdOCeV2cTkuR9cd74552' };
        return {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
    } else {
        return {};
    }
}
export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            //   alert(response.status)
            if (response.status == 400) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (response.status == 404) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (response.status === 422) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (response.status === 401) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            else if (response.status === 500) {
                toast.error("Internal Server Error", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}